/* eslint-disable react/display-name */
import { useState, useEffect } from "react";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { customerServices } from "@/services/customerServices";
import { healerServices } from "@/services/healerServices";
import Layout from "@/components/layout";
import Testimonial from "@/components/customer/testimonials";
import HealerCarousel from "@/components/healer/caraousel";
import HealerCoursesCarousel from "@/components/healer/our-courses-caraousal";
import styles from "@/styles/Home.module.css";
import Faq from "@/components/faq";
import BlogSlider from "@/components/common/blog-slider";
import ExperienceEnergyHealing from "@/components/common/experience-energy-healing";
import Head from "next/head";
import Modalityfilter from "@/components/modalityFilter";
import Symptomsfilter from "@/components/symptomsFilter";

const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

export default function Home(props) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { filters, selected_languages, selected_languages_symp } = useSelector(
    (state) => state.healersPage
  );
  const [active, setActive] = useState("customer");
  const [activeTab, setActiveTab] = useState("customertab");
  const [homeMeta, setHomeMeta] = useState(props?.homeMeta ?? {});
  const [modalities, setModalities] = useState(props?.modalities ?? []);
  const [testimonials, setTestimonial] = useState(props?.testimonials ?? []);
  const [healersList, setHealersList] = useState(props?.healersList ?? []);
  const [coursesList, setCoursesList] = useState(props?.coursesList ?? []);
  const [blogs, setBlogs] = useState(props?.blogs ?? []);
  const [screenSize, setScreenSize] = useState();
  const [searchBar, setSearchBar] = useState(false);
  console.log(process?.env?.NEXT_PUBLIC_BACKEND_URL,"sfdf",process?.env?.NEXT_PUBLIC_FRONTEND_URL);
  //const [selected_languages, set_Selected_languages] = useState([]);
  //const [selected_languages_symp, set_Selected_languages_symp] = useState([]);

  const handleSearch = () => {
    if (filters.symptoms || filters.modalities) {
      router.push("/healers");
    } else {
      toast.warning("Please select modality or enter name.", {
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  return (
    <>
      <Head>
        <title>{homeMeta?.meta?.og_title}</title>
        <meta name="title" content={homeMeta?.meta?.meta_title}></meta>
        <meta
          name="description"
          content={homeMeta?.meta?.meta_description}
        ></meta>
        <meta name="keywords" content={homeMeta?.meta?.meta_keywords}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content={homeMeta?.meta?.og_title}></meta>
        <meta property="og:image" content={homeMeta?.meta?.og_image}></meta>
        <link rel="canonical" href="https://www.zenkify.com/"></link>
        <meta
          property="og:description"
          content={homeMeta?.meta?.meta_description}
        ></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={homeMeta?.meta?.meta_title} />
        <meta
          name="twitter:description"
          content={homeMeta?.meta?.meta_description}
        />
        <meta name="twitter:image" content={homeMeta?.meta?.og_image} />
        <meta property="og:url" content="https://www.zenkify.com/" />
        <meta property="og:site_name" content="Zenkify" />
        <meta property="og:image:alt" content="Healing Services" />
        <meta property="og:image" content={homeMeta?.meta?.og_image}></meta>
      </Head>
      <section className={styles["home-banner"]}>
        <Image
          className={styles["banner-background-img"]}
          priority={true}
          width={1920}
          height={1000}
          src="/images/zenkify-background.jpg"
          alt="Zenkify Background"
        />
        <Container>
          <Row>
            <Col md={7} className="align-self-center order-md-1 order-2">
              <div
                className={
                  styles["banner-content"] + " " + "homemodalites-inputbox"
                }
              >
                <h1>Find your choice of Energy Healers</h1>
                <p>
                  Zenkify provides you a platform where its easy to find and
                  book a session with your favorite healers across the globe
                </p>
                <div className={styles["slider-searchbar"] + " " + "mt-3"}>
                  <Modalityfilter />
                  <Symptomsfilter />
                  <Button
                    className={styles["theme-btn"] + " " + "theme-btn"}
                    onClick={() => handleSearch()}
                    name="search"
                    aria-label="search"
                  >
                    <span>Search</span> <i className="ri-arrow-right-line"></i>
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={5} className="order-md-2 order-1">
              <div className={styles["slider-image"]}>
                <Image
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  src="/images/banner-slider-1.png"
                  alt="Reaiki Healing"
                  priority={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles["welcome-healing"]}>
        <Container>
          <Row>
            <Col md={11} className="m-auto">
              <div className="section-title">
                <h3 className="title">What is Energy Healing</h3>
                <p>
                  Energy healing is a holistic therapy that aims to promote
                  healing by rebalancing the energy systems of the body. It is
                  based on the belief that our bodies have an energy field or
                  aura, and that disturbances or blockages in this field can
                  lead to physical, emotional, or spiritual problems. Energy
                  healers use various techniques to channel or manipulate energy
                  in the body, with the goal of removing blockages and promoting
                  the free flow of energy.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className={styles["tabing-welcome"]}>
                <ul
                  className={"nav" + " " + styles["nav-tabs"]}
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Button
                      className={`${styles["nav-link"]} ${
                        active == "customer"
                          ? "show" + " " + styles["active"]
                          : ""
                      }`}
                      onClick={() => setActive("customer")}
                      data-bs-toggle="tab"
                      data-bs-target="#forcustomer"
                      type="button"
                      role="tab"
                      aria-selected="true"
                    >
                      <Image
                        height={25}
                        width={25}
                        src="/images/reiki-healing.svg"
                        alt="ReikiHealing"
                      />
                      <span>Reiki</span>
                    </Button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Button
                      className={`${styles["nav-link"]} ${
                        active == "healer"
                          ? "show" + " " + styles["active"]
                          : ""
                      }`}
                      onClick={() => setActive("healer")}
                      data-bs-toggle="tab"
                      data-bs-target="#forhealer"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <Image
                        height={25}
                        width={25}
                        src="/images/theta-healing-small-icon.svg"
                        alt="ThetaHealing"
                      />
                      <span>Theta Healing</span>
                    </Button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Button
                      className={`${styles["nav-link"]} ${
                        active == "touch" ? "show" + " " + styles["active"] : ""
                      }`}
                      onClick={() => setActive("touch")}
                      data-bs-toggle="tab"
                      data-bs-target="#healing-touch"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <Image
                        height={25}
                        width={25}
                        src="/images/healing-touch-small-icon.svg"
                        alt="HealingTouch"
                      />
                      <span>Healing Touch</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={12}>
              <div
                className={"tab-content" + " " + styles["tabcontent-welcome"]}
                id="myTabContent"
              >
                <div
                  className={`tab-pane fade ${
                    active == "customer" ? "show" + " " + styles["active"] : ""
                  }`}
                  id="forcustomer"
                >
                  <Row>
                    <Col lg={7} className="align-self-center">
                      <div
                        className={styles["inner-content"] + " " + "pe-lg-5"}
                      >
                        <h3>
                          A Pathway to Healing, Balance, and Inner Harmony
                        </h3>
                        <p>
                          Reiki can be used in conjunction with other forms of
                          therapy, such as traditional medicine and
                          psychotherapy, to provide a more holistic and
                          comprehensive approach to healing and wellness.
                        </p>
                        <h4>Reiki may Help Solve:-</h4>
                        <ul className={styles["HelpSolveIcon"]}>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/stress-anxiety.svg"
                                alt="Stressanxiety"
                              />
                            </div>
                            <span>Stress and anxiety</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/emotional-trauma.svg"
                                alt="Emotionaltrauma"
                              />
                            </div>
                            <span>Depression</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/chronic-fatigue.svg"
                                alt="ChronicFatiguesyndrome"
                              />
                            </div>
                            <span>Emotional trauma</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/depression.svg"
                                alt="Depression"
                              />
                            </div>
                            <span>Insomnia</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/insomnia.svg"
                                alt="Insomnia"
                              />
                            </div>
                            <span>Chronic fatigue syndrome</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className={styles["welecome-tab-image"]}>
                        <Image
                          height={449}
                          width={731}
                          style={{ height: "auto", width: "auto" }}
                          priority={true}
                          src="/images/energy-healing.png"
                          alt="energy-healing"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={`tab-pane fade ${
                    active == "healer" ? "show" + " " + styles["active"] : ""
                  }`}
                  id="forhealer"
                >
                  <Row>
                    <Col lg={7} className="align-self-center">
                      <div
                        className={styles["inner-content"] + " " + "pe-lg-5"}
                      >
                        <h3>
                          Rewire Your Mind, Heal Your Body, and Manifest Your
                          Dreams
                        </h3>
                        <p>
                          Theta Healing is gentle and yet powerful approach to
                          healing, allowing individuals to release energetic
                          blocks, connect with their inner wisdom, and create
                          positive changes in all areas of life.
                        </p>
                        <h4>Theta Healing may Help Solve:-</h4>
                        <ul className={styles["HelpSolveIcon"]}>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/stress-anxiety.svg"
                                alt="Stress and anxiety"
                              />
                            </div>
                            <span> Emotional Imbalances</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/emotional-trauma.svg"
                                alt="Emotional trauma"
                              />
                            </div>
                            <span> Negative thought patterns</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/chronic-fatigue.svg"
                                alt="Chronic fatigue syndrome"
                              />
                            </div>{" "}
                            <span>Relationship challenges</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/depression.svg"
                                alt="Depression"
                              />
                            </div>
                            <span> Phobias and fears</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/insomnia.svg"
                                alt="Insomnia"
                              />{" "}
                            </div>
                            <span> Past traumas</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className={styles["welecome-tab-image"]}>
                        <Image
                          height={731}
                          width={449}
                          src="/images/energy-healing.png"
                          alt="energy-healing"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={`tab-pane fade ${
                    active == "touch" ? "show" + " " + styles["active"] : ""
                  }`}
                  id="forhealingtouch"
                >
                  <Row>
                    <Col lg={7} className="align-self-center">
                      <div
                        className={styles["inner-content"] + " " + "pe-lg-5"}
                      >
                        <h3>
                          Nurturing Body, Mind, and Spirit with Therapeutic
                          Energy
                        </h3>
                        <p>
                          Healing Touch is a non-invasive energy based therapy
                          that promotes balancing and clearing the body&apos;s
                          energy centers and enhances the mind-body-spirit
                          connection.
                        </p>
                        <h4>Healing Touch may Help Solve:-</h4>
                        <ul className={styles["HelpSolveIcon"]}>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/stress-anxiety.svg"
                                alt="Stress and anxiety"
                              />
                            </div>
                            <span>Physical pain and discomfort</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/emotional-trauma.svg"
                                alt="Emotional trauma"
                              />
                            </div>
                            <span>Sleep disturbances and insomnia</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/chronic-fatigue.svg"
                                alt="Chronic fatigue syndrome"
                              />
                            </div>
                            <span>Fatigue and low energy levels</span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/depression.svg"
                                alt="Depression"
                              />
                            </div>
                            <span>
                              Easing side effects of medical treatments
                            </span>
                          </li>
                          <li>
                            <div className={styles["iconImg"]}>
                              <Image
                                height={35}
                                width={35}
                                src="/images/insomnia.svg"
                                alt="Insomnia"
                              />
                            </div>
                            <span>Chronic illnesses and conditions</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className={styles["welecome-tab-image"]}>
                        <Image
                          height={731}
                          width={449}
                          src="/images/energy-healing.png"
                          alt="energy-healing"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <hr />
      <section className={styles["bringfor-your"]}>
        <Container>
          <Row>
            <Col md={10} className="m-auto">
              <div className={styles["section-title"] + " " + "section-title"}>
                <h3 className={styles["title"] + " " + "title"}>
                  What Zenkify Brings For You
                </h3>
                <p>
                  Zenkify offers a comprehensive and convenient avenue to
                  explore and benefit from the power of energy healing.It brings
                  a transformative and empowering experience by connecting you
                  with a diverse range of energy healing modalities and
                  practitioners.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={styles["tabing-customer"]}>
                <ul
                  className={"nav" + " " + styles["nav-tabs"]}
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <Button
                      className={`${styles["nav-link"]} ${
                        activeTab == "customertab"
                          ? "show" + " " + styles["active"]
                          : ""
                      }`}
                      onClick={() => setActiveTab("customertab")}
                      data-bs-toggle="tab"
                      data-bs-target="#forcustomer"
                      type="button"
                      role="tab"
                      aria-selected="true"
                    >
                      <Image
                        height={25}
                        width={25}
                        src="/images/for-customer.svg"
                        alt="for-customer"
                      />
                      <span>Customer</span>
                    </Button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Button
                      className={`${styles["nav-link"]} ${
                        activeTab == "healertab"
                          ? "show" + " " + styles["active"]
                          : ""
                      }`}
                      onClick={() => setActiveTab("healertab")}
                      data-bs-toggle="tab"
                      data-bs-target="#forhealer"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <Image
                        height={25}
                        width={25}
                        src="/images/healer.svg"
                        alt="for-customer"
                      />
                      <span>Healer</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={12}>
              <div
                className={"tab-content" + " " + styles["tabcontent-customer"]}
                id="myTabContent"
              >
                <div
                  className={`tab-pane fade ${
                    activeTab == "customertab"
                      ? "show" + " " + styles["active"]
                      : ""
                  }`}
                  id="forcustomer"
                >
                  <Row className="topSectionHeading">
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/finding-energy.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              One Stop Shop in finding Energy <br /> Healers
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/select-healer.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              Select Healer from anywhere on
                              <br /> the globe
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/book-session.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              Book session with your favorite
                              <br /> healers
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-5 topSectionHeading">
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/signup.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>1. SignUp and See the List of Healers</h4>
                          <p>
                            You can register yourself with Zenkify to access
                            energy healing services from skilled and verified
                            Healers from around the world.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/updated-calendar.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>2. See their Updated Calendar</h4>
                          <p>
                            You can access the updated calendar of Healers to
                            book a session at your convenience.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/book-and-pay.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>3. Booking and Easy Payment </h4>
                          <p>
                            After checking the calendar of your shortlisted
                            healers you can book the session and make secure
                            payment.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab == "healertab"
                      ? "show" + " " + styles["active"]
                      : ""
                  }`}
                  id="forhealer"
                >
                  <Row className="topSectionHeading">
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/finding-energy.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              Get Clients from all over
                              <br /> the world
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/select-healer.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              Making it easy for your clients to Book and Pay
                              for services
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["forcustomer-head"]}>
                          <div className={styles["icon"]}>
                            <Image
                              height={60}
                              width={60}
                              src="/images/book-session.svg"
                              alt="finding Energy"
                            />
                          </div>
                          <div className={styles["head-content"]}>
                            <p>
                              Save Time & Effort in creating your Energy Healing
                              Business
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-5 topSectionHeading">
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/signup.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>1. Create Zenkify Profile</h4>
                          <p>
                            It is easy to start with the transformative journey
                            and be a part of Energy Healing Community by Simple
                            Sign Up
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/updated-calendar.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>2. Keep Updated Calendar</h4>
                          <p>
                            Zenkify allows you to update the calendar as per
                            your availability ensuring flexibilty and
                            feasibility to you.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className={styles["forcustomer-content"]}>
                        <div className={styles["middle-image"]}>
                          <Image
                            height={361}
                            width={320}
                            src="/images/book-and-pay.svg"
                            alt="signup"
                          />
                        </div>
                        <div className={styles["forcustomer-bottom"]}>
                          <h4>3. Grow your Practice</h4>
                          <p>
                            With Zenkify you can provide hassel free Energy
                            Healing services across the globe with minimal
                            expense and wide outreach.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <HealerCarousel props={{ healersList }} />
      <HealerCoursesCarousel props={{ coursesList }} />
      <hr />
      <Faq />
      <Testimonial props={{ testimonials }} />
      <ExperienceEnergyHealing />
      {blogs.length != 0 ? (
        <section className={styles["blog-section"]}>
          <Container>
            <Row>
              <Col md={9} className=" m-auto">
                <div className="section-title">
                  <h3 className="title">
                    Our Latest & Most Popular Tips & Tricks For You
                  </h3>
                  <p>
                    Enhance Your Practice with Expert Advice. Discover New
                    Techniques, Insights, and Best Practices for Energy Healing.
                    Stay Updated with Our Latest Tips & Tricks for Optimal
                    Healing Results.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <BlogSlider props={{ blogs }} />
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        ""
      )}
      <section
        className={`${styles["content-grid"]} ${styles["healing-touch"]}`}
      >
        <Image
          width={150}
          height={101}
          src="/images/flower-shap-3.svg"
          className={styles["flower-top"]}
          alt="flower-shap"
        />
        <Container>
          <Row className="gy-5">
            <Col lg={12}>
              <Row>
                
                <Col lg={7} >
                  <div className={styles["inner-content"]+" "+"section-title text-start"}>
                    <h3 className="title">
                      Why Choose Zenkify Energy Healing and Reiki Healing
                      Services?
                    </h3>
                    <p>
                      Holistic wellness is of utmost importance for every
                      individual. It ensures a person is physically, mentally,
                      and spiritually healthy. And that is where we, Zenkify,
                      come into the picture and offer the best energy healing
                      and reiki healing services in USA for holistic wellness.
                      We have certified practitioners providing a range of
                      healing modalities, such as reiki healing, Theta Healing,
                      and Healing Touch. We are a cutting-edge online platform
                      created to provide people with easy access to energy
                      healing services. Reiki healing services in USA is an
                      excellent therapy that can help remove stress, anxiety,
                      and many other issues. Our practitioners will assist in
                      guiding the positive energy flow throughout your body and
                      do the healing.</p>
                      <p>
                      Zenkify is platform where you can find the top reiki &
                      Theta healers in USA. We are known for our experienced and
                      certified practitioners and energy healers. Their guidance
                      will ensure you receive high-quality sessions. We believe
                      in offering holistic well-being through our healing
                      practices. Therefore, our practitioners work with their
                      clients to understand their needs and create tailor-made
                      plans to help them improve physically, emotionally, and
                      spiritually. Our primary goals are to advance self-care
                      and comprehensive well-being. This dedication is in line
                      with those looking for all-natural, non-invasive methods
                      of relaxation and healing. Choose Zenkify today and feel
                      the change yourself.
                    </p>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className={styles["home-whychoose"]+" "+"text-center position-sticky"}>
                    <Image
                      width={400}
                      height={500}
                      src="/images/reasons-choose.svg"
                      alt="Healing Touch Service"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Image
          width={79}
          height={130}
          src="/images/icon/flower-2.svg"
          className={styles["flower-bottom"]}
          alt="flower-shap"
        />
      </section>
    </>
  );
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

// ssr
export async function getServerSideProps() {
  const fetchModalities = async () => {
    try {
      const res = await healerServices.getModalities();
      if (res.status) {
        return res.data.map((i) => ({ value: i.id, label: i.name }));
      }
    } catch (error) {
      console.error("Error fetching modalities data:", error);
    }
  };

  const fetchSymptoms = async () => {
    const res = await healerServices.getSymptoms();
    if (res.status) {
      return res.data.map((i) => ({ value: i.id, label: i.name }));
    } else {
      toast.error(res.message, {
        autoClose: 5000,
      });
    }
  };

  const fetchTestimonials = async () => {
    try {
      const res = await customerServices.getTestimonials();
      if (res.status) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching testimonials data:", error);
    }
  };

  const fetchHealersList = async () => {
    try {
      const res = await healerServices.getHealers();
      if (res.status) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching healers data:", error);
    }
  };

  const fetchCoursesList = async () => {
    try {
      const res = await healerServices.getHealerCourses();
      if (res.status) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching courses data:", error);
    }
  };

  const fetchBlogsList = async () => {
    try {
      const res = await healerServices.getBlogsData({ page: 1 });
      if (res.status) {
        return res.data.data;
      }
    } catch (error) {
      console.error("Error fetching slider data:", error);
    }
  };

  const fetchHomeMeta = async () => {
    try {
      const res = await healerServices.getMetaInformation("home");
      if (res.status) {
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching slider data:", error);
    }
  };

  const modalities = (await fetchModalities()) ?? {};
  const testimonials = (await fetchTestimonials()) ?? {};
  const healersList = (await fetchHealersList()) ?? {};
  const coursesList = (await fetchCoursesList()) ?? {};
  const blogs = (await fetchBlogsList()) ?? {};
  const homeMeta = (await fetchHomeMeta()) ?? {};
  const symptoms = (await fetchSymptoms()) ?? {};

  // Pass data to the page via props
  return {
    props: {
      modalities,
      homeMeta,
      testimonials,
      healersList,
      coursesList,
      blogs,
      symptoms,
    },
  };
}
